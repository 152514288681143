import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
   faBirthdayCake,
   faClock,
   faComments,
   faGraduationCap,
   faHeadphones,
   faHeartbeat,
   faHome,
   faSitemap,
   faStethoscope
} from "@fortawesome/pro-light-svg-icons"

const BenefitsContainer = ({benefitsNodes, horizontal}) => {

   const getIcon = name => {
      switch (name) {
         case 'stethoscope':
            return faStethoscope
         case 'heartbeat':
            return faHeartbeat
         case 'clock':
            return faClock
         case 'comments':
            return faComments
         case 'headphones':
            return faHeadphones
         case 'birthday-cake':
            return faBirthdayCake
         case 'graduation-cap':
            return faGraduationCap
         case 'sitemap':
            return faSitemap
         case 'home':
            return faHome
         default: 
            return null;
      }
   }

   return (
      <div className="row benefit-list">
         {benefitsNodes &&
         benefitsNodes.map(({node}, index) => (
            <div key={index} className={`${horizontal ? 'col-lg-4' : 'col-lg-12'} col-sm-6 col-xs-12`}>
               <div className='benefit-item' id={`benefit-${node.id}`}>
                      <span className='benefit-item-icon d-inline-flex justify-content-center align-items-center'>
                        <FontAwesomeIcon
                           icon={getIcon(node.icon)}
                           size='lg'
                           className={`text-${node.color}`}
                        />
                      </span>
                  <span className='benefit-item-label'>
                        &nbsp;
                     {node.title}
                      </span>
               </div>
            </div>
         ))}
      </div>
   )
}

export default BenefitsContainer