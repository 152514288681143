import React from 'react'
import NavBar from "../components/common/NavBar"
import JobIntro from "../components/about/career/JobIntro"
import JobContent from "../components/about/career/JobContent"
import Helmet from "react-helmet"
import Layout from '../layouts';
import { graphql } from "gatsby"

const Job = ({ data }) => {

  const { email, phone } = data.job_contact.contact
  const { job } = data
  const technologiesNodes = data.job_technologies.edges
  const benefitsNodes = data.benefits.edges

  return (
    <Layout>
      <Helmet
        title={`${job.title} | Career | Zaven`}
        meta={[
          { name: 'description', content: job.description },
        ]}
      />
      <NavBar email={email} phoneNumber={phone} />
      <JobIntro />
      <JobContent job={job} technologiesNodes={technologiesNodes} benefitsNodes={benefitsNodes} />
    </Layout>
  )
}

export default Job

export const query = graphql`
  query JobQuery($slug: String!, $job_technologies: String!) {
    job: jobsYaml(fields: {slug: { eq: $slug } }) {
      ...Job
    }
    job_technologies: allTechnologiesJson(filter: {short: {regex: $job_technologies }}) {
      ...Technologies
    }
    job_contact: dataJson {
      ...Contact
    }
    benefits: allCareerBenefitsJson {
      ...Benefits
    }
  }
`
