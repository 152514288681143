import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBalanceScale,
  faCode,
  faEdit,
  faEnvelope,
  faFileAlt,
} from '@fortawesome/pro-light-svg-icons'
import {
  faAngleLeft,
  faCoins,
  faMapMarkerAlt,
} from '@fortawesome/pro-solid-svg-icons'
import BenefitsContainer from './BenefitsContainer'

const JobContent = ({ technologiesNodes, job, benefitsNodes }) => (
  <div className="container">
    <article className="page-article">
      <h3>
        <Link to="/career/" className="link-unstyled">
          <FontAwesomeIcon
            icon={faAngleLeft}
            style={{ position: 'relative', top: '-1px' }}
          />{' '}
          Career
        </Link>
        <span className="text-muted"> / Open positions</span>
      </h3>
      <div className="row">
        <div className="col-md-6">
          <h1 className="my-1">{job.title}</h1>
          <p className="text-uppercase text-sm text-muted mb-0">
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              style={{ margin: '0 0.2rem' }}
            />{' '}
            {job.job_location}
            <br />
            <FontAwesomeIcon
              icon={faCoins}
              style={{ margin: '0 0.1rem' }}
            />{' '}
            {job.job_salary}
            <br />
          </p>
        </div>
        <div className="col-md-6">
          <div className="text-sm-center text-md-right technologies py-0">
            {technologiesNodes &&
              technologiesNodes.map(({ node }, index) => (
                <span key={index} className="technology">
                  <img
                    src={node.image.childImageSharp.fluid.src}
                    alt={node.title}
                  />
                </span>
              ))}
          </div>
        </div>
      </div>
      <hr className="my-5" />
      <div className="row">
        <div className="col-md-6 mb-5">
          {job.skill_requirement && (
            <div>
              <h3>Skills/requirements</h3>
              <ul>
                {job.skill_requirement.map((skill, index) => (
                  <li key={index}>{skill}</li>
                ))}
              </ul>
            </div>
          )}

          {job.responsibilities && (
            <div>
              <h3>your responsibilities</h3>
              <ul>
                {job.responsibilities.map((r, index) => (
                  <li key={index}>{r}</li>
                ))}
              </ul>
            </div>
          )}

          {job.our_offer && (
            <div>
              <h3>What we offer</h3>
              <ul>
                {job.our_offer.map((offer, index) => (
                  <li key={index}>{offer}</li>
                ))}
              </ul>
            </div>
          )}

          {job.contract_options && (
            <div>
              <h3>contract options</h3>
              <ul>
                {job.contract_options.map((option, index) => (
                  <li key={index}>{option}</li>
                ))}
              </ul>
            </div>
          )}

          {job.note && (job.note = job.note.split('%')) && (
            <p>
              Note: {job.note[0]}
              <strong>{job.note[1]}</strong>
              {job.note[2]}
            </p>
          )}
        </div>
        <div className="col-md-5 offset-md-1">
          <div className="card card-default">
            <div className="card-header">
              <p className="lead my-1">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="float-left text-muted mr-4 mt-2"
                  size="2x"
                />
                <strong>Apply now</strong> by contacting <br />
                <a href="mailto:work@zaven.co">work@zaven.co</a>
              </p>
            </div>
            <div className="card-body">
              <p>Please include:</p>
              <ul className="fa-ul">
                <li>
                  <FontAwesomeIcon className="fa-li mt-1" icon={faFileAlt} />{' '}
                  Your resume (PDF, DOC)
                </li>
                <li>
                  <FontAwesomeIcon className="fa-li mt-1" icon={faEdit} /> Name
                  of the job you apply for (in the email title)
                </li>
                <li>
                  <FontAwesomeIcon className="fa-li mt-1" icon={faCode} />{' '}
                  Examples of your work
                </li>
                <li>
                  <FontAwesomeIcon
                    className="fa-li mt-1"
                    icon={faBalanceScale}
                  />{' '}
                  Data processing consent:
                  <br />
                  <p className="text-sm text-muted">
                    <em>
                      “I agree to the processing of personal data contained in
                      my application by Zaven Sp. z o.o. based in Wrocław for
                      the recruitment process."
                    </em>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr className="my-5" />
      <p className="seo-h2 mb-5">Benefits</p>
      <div className="row benefit-list">
        {benefitsNodes &&
          (<p className="seo-h2 mb-5">Benefits</p> ,
            <BenefitsContainer horizontal benefitsNodes={benefitsNodes} />)}
      </div>
      <hr className="my-5" />
      <p className="seo-h2 mb-5">Conditions</p>
      <div className="row">
        <div className="col-md-12">
          <p className="text-sm text-muted">
            Zaven Sp. z o.o. with its headquarters at ul. Sienna 5b, 51-349
            Wrocław, Poland is the administrator of personal data (email
            address: <a href="mailto:work@zaven.co">work@zaven.co</a>
            ).
          </p>
          <p className="text-sm text-muted">
            Providing personal data in a CV is voluntary but necessary for the
            recruitment process. The consequence of not providing personal data
            will result in the inability to conduct recruitment proceedings.
            Personal data will be processed on the basis of art. 6 par. 1, point
            a and c of the general regulation on the protection of personal data
            of April 27, 2016 (GDPR).
          </p>
          <p className="text-sm text-muted">
            You have the right to access your personal data and the right to
            rectify, delete, limit processing, the right to transfer data, the
            right to raise objections, the right to revoke the consent to their
            processing at any time without affecting the lawfulness of the
            processing, which was performed on the basis of consent prior to its
            withdrawal. You have the right to file a complaint regarding the
            processing of data to the President of the Office of Personal Data
            Protection.
          </p>
          <p className="text-sm text-muted">
            Personal data will be processed until the completion of recruitment,
            but no longer than three months from the date of receiving your CV.
            With the consent of the candidates, personal data will be processed
            in subsequent recruitments conducted by the personal data
            administrator for a period of five years. Personal data of
            candidates will not be subject to automated processing.
          </p>
          <p className="text-sm text-muted">
            Please, give consent in the application with the following content:
            <br />
            <i>
              "I agree to the processing of personal data contained in my
              application by Zaven Sp. z o.o. based in Wrocław for the
              recruitment process."
            </i>
          </p>
          <p className="text-sm text-muted">
            If you also agree to the processing of personal data after the
            recruitment process for future recruitment, please provide a second
            consent with the following content:
            <br />
            <i>
              "I agree to the processing of personal data contained in my
              application by Zaven Sp. z o.o. based in Wroclaw for the purposes
              of future recruitment processes, including other positions."
            </i>
          </p>
        </div>
      </div>
    </article>
  </div>
)

export default JobContent
